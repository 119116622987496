<template>
  <div class="refarral">
    <div class="text-h4 font-weight-bold mb-6">{{ $t('referral.title') }}</div>
    <div class="flex-container">
      <v-card class="pa-md-9 px-3 py-5 card-item" rounded="lg" flat max-width="1026" width="100%">
        <div class="gray--text">{{ $t('referral.desc1') }}</div>
        <div class="gray--text mb-6">{{ $t('referral.desc2') }}</div>
        <div class="font-weight-bold mb-16">{{ $t('referral.infoTitle') }}</div>
        <div class="text-center text-sm-left">
          <div class="gray--text">{{ $t('referral.total') }}</div>
          <div class="font-weight-black text-h3 mb-6">$100.00</div>
        </div>
        <div class="d-flex mb-14">
          <v-card
            tile
            flat
            outlined
            max-width="316px"
            width="100%"
            height="91"
            class="rounded-l d-flex flex-column justify-center align-center"
          >
            <div class="f30 font-weight-bold">10</div>
            <div class="gray--text">{{ $t('referral.sent') }}</div>
          </v-card>
          <v-card tile flat max-width="316px" width="100%" height="91" class="primary d-flex flex-column justify-center align-center">
            <div class="f30 font-weight-bold white--text">40</div>
            <div class="white--text">{{ $t('referral.registered') }}</div>
          </v-card>
          <v-card
            tile
            flat
            outlined
            max-width="316px"
            width="100%"
            height="91"
            class="rounded-r d-flex flex-column justify-center align-center"
          >
            <div class="f30 font-weight-bold">0</div>
            <div class="gray--text">{{ $t('referral.funded') }}</div>
          </v-card>
        </div>
        <v-card rounded="lg" flat width="100%" class="pa-sm-7 table-card">
          <div class="f18 font-weight-bold mb-4">{{ $t('referral.registered') }}</div>
          <v-data-table hide-default-footer hide-default-header :items="items">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, i) in items" :key="i" height="60" class="black--text">
                  <td class="text-body-1">{{ item.name }}</td>
                  <td class="text-body-1 text-center">{{ new Date(item.date).toLocaleDateString() }}</td>
                  <td class="text-body-1 font-weight-bold text-right">${{ (item.amount / 100).toFixed(2) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
      <v-card class="mb-5 pa-md-9 px-3 py-5 text-center black--text card-item" rounded="lg" flat>
        <div class="font-weight-bold mb-6">{{ $t('referral.shareLink') }}</div>
        <div class="d-flex align-end mb-6">
          <v-text-field class="field64" v-model="link" hide-details outlined required color="primary" height="64"></v-text-field>
          <v-btn :disabled="!link" @click="copy" depressed class="primary ml-n4" height="60" width="115">{{ $t('btn.copy') }}</v-btn>
        </div>
        <div class="d-flex justify-space-between align-center mb-6 px-md-0 px-sm-10 px-0">
          <v-btn outlined class="btn-social">
            <a href="viber://chat?number=+380507378178" target="_blank">
              <v-img heigth="24" max-width="24" src="@/assets/icon/viber.svg"></v-img>
            </a>
          </v-btn>
          <v-btn outlined class="btn-social">
            <v-img heigth="24" max-width="24" src="@/assets/icon/facebook.svg"></v-img>
          </v-btn>
          <v-btn outlined class="btn-social">
            <v-img heigth="24" max-width="24" src="@/assets/icon/whatsapp.svg"></v-img>
          </v-btn>
          <v-btn outlined class="btn-social">
            <v-img heigth="24" max-width="24" src="@/assets/icon/telegram.svg"></v-img>
          </v-btn>
        </div>
        <div class="d-flex align-center mb-6">
          <v-divider></v-divider>
          <div class="mx-5 text-body-2 gray--text">{{ $t('or') }}</div>
          <v-divider></v-divider>
        </div>
        <v-btn depressed class="primary" height="64" width="248">{{ $t('btn.emailFriends') }}</v-btn>
      </v-card>
    </div>
    <v-snackbar elevation="0" height="72" rounded="b-lg t-0" top v-model="snackbar" color="success" class="mt-16">
      <div class="black--text d-flex align-center justify-center">
        <v-img class="mr-6 icon" heigth="30" max-width="30" src="@/assets/icon/checked.svg"></v-img>
        <div>{{ $t('referral.copyLink') }}</div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      items: [
        { name: 'Murad', amount: 10000, date: 1634738033679 },
        { name: 'Murad', amount: 15000, date: 1634738033679 },
        { name: 'Murad', amount: 30000, date: 1634738033679 },
      ],
      snackbar: false,
      link: '',
    };
  },
  methods: {
    copy() {
      this.$copyText(this.link).then(() => {
        this.snackbar = true;
      });
    },
  },
};
</script>

<style lang="scss">
.refarral {
  .flex-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .card-item:last-child {
    width: 498px;
    margin-left: 28px;
  }
  .btn-social {
    width: 102px !important;
    height: 56px !important;
  }
  .icon {
    filter: invert(0%) brightness(10%) contrast(100%);
  }
}
@media screen and (max-width: 1099px) {
  .refarral {
    .flex-container {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
    .card-item:last-child {
      width: 100%;
      margin-left: 0;
    }
    .table-card {
      background: var(--v-accent-base);
    }
  }
}
@media screen and (max-width: 599px) {
  .refarral {
    .btn-social {
      width: 76px !important;
      height: 42px !important;
    }
    .table-card {
      background: initial;
    }
  }
}
</style>
